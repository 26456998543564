#page-lastras,
#page-lastras * {
    box-sizing: border-box;
    --titleblock-width: 332px;
    --titleblock-height: 360px;
}
/* Cabeçalho */
#page-lastras header {
    width: 100%;
    height: calc(100vh - var(--mainmenu-height));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    background: url("/data/lastras/lastras-banner-202106.jpg") center center no-repeat;
    background-size: cover;
}
#page-lastras header > div {
    width: var(--titleblock-width);
    height: var(--titleblock-height);
    padding: 0 70px 56px;
    background: var(--color-white);
}
#page-lastras header > div h1 {
    display: block;
    margin: -15px 0 30px;
    /*opacity: 0;*/
    opacity: 1;
}
#page-lastras header > div .subtitle {
    display: block;
    margin: 0 0 20px;
    font-size: 1.75rem;
    font-weight: 700;
    text-transform: uppercase;
    /*opacity: 0;*/
    opacity: 1;
}
#page-lastras header > div p {
    display: block;
    margin: 0;
    font-size: 1.125rem;
    font-weight: 300;
}
#page-lastras header a {
    width: 86px;
    display: inline-block;
    position: absolute;
    left: calc(50% - 43px);
    bottom: 66px;
    text-align: center;
}
#page-lastras header a span.label {
    display: block;
    font-size: 1rem;
    text-align: center;
    font-weight: 300;
    color: var(--color-white);
    text-transform: uppercase;
}
#page-lastras header a img {
    display: block;
    margin: 0 auto -40px;
    text-align: center;
}
#page-lastras header a span.block {
    width: 65px;
    height: 42px;
    margin: 20px auto 0;
    display: block;
    background: var(--color-white);
}
@media (max-width: 992px) {
    #page-lastras header {
        height: auto;
        display: block;
        padding: 40px 28px 0;
        background: none;
    }
    #page-lastras header > div {
        width: 100%;
        height: auto;
        padding: 0;
        background: var(--color-white);
    }
    #page-lastras header > div h1 {
        margin: 0px 0 30px;
        opacity: 1;
    }
    #page-lastras header > div .subtitle {
        opacity: 1;
    }
    #page-lastras header a {
        display: none;
    }
}
/* Menu Fixo */
#page-lastras--container .navaux {
    /* This is needed as fix for minification when using division inside calc  */
    --division-value: calc(var(--titleblock-height) / 2); 
    display: block;
    position: absolute;
    top: var(var(--mainmenu-height) + 20px);
    left: 70px;
    /*z-index: 1000;*/
    z-index: 0;
}
#page-lastras--container .navaux.scrolled {
    position: fixed;
    top: 120px;
}
#page-lastras--container .navaux .pretitle {
    margin: 0 0 36px;
}
#page-lastras--container .navaux .pretitle span {
    display: block;
    font-size: 0.75rem;
    font-weight: 300;
    color: var(--color-darker-alt);
}
#page-lastras--container .navaux .title {
    display: block;
    margin: 30px 0 45px;
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--color-darkest);
    text-transform: uppercase;
}
#page-lastras--container .navaux ul {
    max-width: 300px;
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: var(--container-grid-spacer);
    row-gap: calc(var(--container-grid-spacer)/2);
}
#page-lastras--container .navaux ul li  a {
    font-weight: 500;
    font-size: 0.875rem;
    color: var(--color-light);
    text-decoration: none;
}
@media (max-width: 992px) {
    #page-lastras--container .navaux {
        display: none;
    }
}
/* Conceito */
#page-lastras--concept {
    padding-top: 96px;
}
#page-lastras--concept .subtitle {
    display: block;
    margin: 0;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
}
#page-lastras--concept .subtitle-202106 {
    display: block;
    margin: 0 0 48px;
    font-size: 1.72rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
}
#page-lastras--concept .image-full {
    margin-top: 72px;
    max-width: 100%;
}
#page-lastras--concept h2 {
    display: block;
    margin: 0 0 8px;
    font-size: 3.25rem;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase;
    color: var(--color-unltd);
}
#page-lastras--concept .slogan {
    margin: 0 0 66px;
    font-size: 1.75rem;
}
#page-lastras--concept .content p {
    margin: 0 0 1.125rem;
    font-size: 1.125rem;
    font-weight: 300;
    color: var(--color-darker-alt);
    line-height: 1.5em;
}
#page-lastras--concept .content b {
    font-weight: 700;
}
@media (max-width: 992px) {   
    #page-lastras--concept .slogan {
        margin: 0 0 50px;
        font-size: 1.125rem;
    }
    #page-lastras--concept .content p {
        margin: 0 0 1rem;
        font-size: 1rem;
    }
}
@media (max-width: 440px)
{
    #page-lastras--concept {
        padding-top: 36px;
    }
}
/* Escala */
#page-lastras--scale {
    padding-top: 148px;
}
#page-lastras--scale h2 {
    display: block;
    margin: 0 0 30px;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
}
#page-lastras--scale .content {
    max-width: 525px;
    margin: 0 0 134px;
}
#page-lastras--scale .content p {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    color: var(--color-darker-alt);
    line-height: 1.5em;
}
#page-lastras--scale .content b {
    font-weight: 700;
}
#page-lastras--scale .scale-container {
    width: calc(100% + 60px);
    margin-right: -60px;
    padding-bottom: 24px;
    position: relative;
    overflow-x: auto;
}
#page-lastras--scale .scale > img {
  opacity: 0.8;
}
#page-lastras--scale .scale {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    bottom: 0px;
    left: -50px;
}
#page-lastras--scale .scale span {
    display: block;
    max-width: 160px;
    margin: 0 20px 60px 0; 
    font-size: 1.25rem;
    text-align: right;

}
#page-lastras--scale .scale-holder {
    position: relative;
    /*overflow: auto;
    width: 100%;*/
}
#page-lastras--scale .scale-container .scale-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
}
#page-lastras--scale .scale-container .scale-wrapper .scale-item span {
    display: block;
    font-size: 0.875rem;
    margin: 0 0 24px;
}
#page-lastras--scale .scale-container .scale-wrapper .scale-item img {
    display: block;
}
#page-lastras--scale .more {
    text-align: right;
}
#page-lastras--scale .more img {
    margin: 0 20px 0 0;
}
#page-lastras--scale .more  span {
    font-size: 1rem;
    color: var(--color-light);
    text-decoration: none;
    text-transform: uppercase;
}
#page-lastras--scale .scale-holder img
{
    width: 98%;   
}
@media (min-width:1600px) {
    #page-lastras--scale {
        /*background: url("/data/lastras/lastras-bg-01.png") calc(100vw - 258px - 76px - (100vw - var(--max-container))/2) 180px no-repeat;*/
    }
}
@media (max-width:992px) {
    #page-lastras--scale {
        background: none;
    }
    #page-lastras--scale .scale-container {
        width: calc(100% + 28px);
        margin-right: -28px;
    }
    #page-lastras--scale .content {
        max-width: 525px;
        margin: 0 0 28px;
    }
    #page-lastras--scale .scale {
        bottom: 0px;
        right: -20px;
        left: auto;
    }
    #page-lastras--scale .more {
        display: none;
    }
    #page-lastras--scale .scale-holder img
    {
        width: 100%;   
    }
}
/* Usos */
#page-lastras--uses {
    padding-top: 116px;
}
#page-lastras--uses .subtitle-202106 {
    display: block;
    margin: 0 0 68px;
    font-size: 1.72rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: uppercase;
}
#page-lastras--uses .uses--container {
}
#page-lastras--uses .uses--container .uses--wrapper {
    display: grid;
    grid-template-columns: repeat(8,minmax(78px,1fr));
    grid-template-areas: "revestir revestir spacer01 mobiliar mobiliar spacer02 decorar decorar";
    gap: var(--container-grid-spacer);
}
#page-lastras--uses .uses--container .uses-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 130px 70px auto;
    grid-template-areas: "image" "title" "content";
    gap: 40px;
}
#page-lastras--uses .uses--container .uses-item > img {
    grid-area: image;
    justify-self: center;
    align-self: center;
}
#page-lastras--uses .uses--container .uses-item > div {
    grid-area: title;
}
#page-lastras--uses .uses--container .uses-item > div .title-pre {
    display: block;
    margin: 0;
    font-size: 1.125rem;
    font-weight: 300;
    text-transform: uppercase;
}
#page-lastras--uses .uses--container .uses-item > div .title {
    margin: 0;
    font-size: 1.75rem;
}
#page-lastras--uses .uses--container .uses-item > ul {
    grid-area: content;
    list-style-type: "-  ";
    padding: 0 0 0 10px;
}
#page-lastras--uses .uses--container .uses-item > ul li {
    font-weight: 300;
}
#page-lastras--uses .uses--container .uses-item:nth-of-type(1) {
    grid-area: revestir;
}
#page-lastras--uses .uses--container .uses-item:nth-of-type(2) {
    grid-area: mobiliar;
}
#page-lastras--uses .uses--container .uses-item:nth-of-type(3) {
    grid-area: decorar;
}
@media (max-width: 1402px) {
    #page-lastras--uses .uses--container .uses--wrapper {
        grid-template-columns: repeat(8,1fr);
    }
}
@media (max-width: 992px) {
    #page-lastras--uses {
        padding-top: 60px;
    }
    #page-lastras--uses .uses--container {
        width: calc(100% + 28px);
        margin: 0 0 0 auto;
        overflow-x: auto;
    }
    #page-lastras--uses .uses--container .uses--wrapper {
        display: grid;
        grid-template-columns: repeat(8,minmax(78px,1fr));
        grid-template-areas: "revestir revestir spacer01 mobiliar mobiliar spacer02 decorar decorar";
        gap: calc(var(--container-grid-spacer) /2);
    }
}
/* Futuro */
#page-lastras--futuro {
    padding-top: 174px;
}
#page-lastras--futuro h2 {
    width: 80%;
    margin: 0 0 70px;
    display: block;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
}
#page-lastras--futuro p {
    margin: 0 0 60px;
    font-size: 0.875rem;
    color: var(--color-darker-alt);
    line-height: 1.5em;
}
#page-lastras--futuro b {
    font-weight: 700;
}
#page-lastras--futuro img{
    width: 100%;
}
#page-lastras--futuro .custom-grid-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}
#page-lastras--futuro .custom-grid-icons .item {
    width: 96px;
    text-align: center;
}
#page-lastras--futuro .custom-grid-icons .item:nth-of-type(even) {
    justify-self: end;
}
#page-lastras--futuro p {
    margin: 60px 0 0;
    line-height: 1.7;
}
@media (max-width:992px) {
    #page-lastras--futuro h2 {
        font-size: 1.125rem;
    }
    #page-lastras--futuro .custom-grid-icons {
        margin-bottom: 60px;
    }
}
/* Listagem de Linhas */
#lastraslines {
    padding-top: 294px;
}
#lastraslines h2 {
    margin: 0 28px 42px;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1em;
    text-transform: none;
}
@media (max-width:992px) {
    #lastraslines {
        padding-top: 133px;
    }
}
